<div class="accordion theme-accordion">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button"
        type="button"
        [attr.data-bs-toggle]="'collapse'"
        [attr.data-bs-target]="'#' + itemId"
        [attr.aria-expanded]="open"
        (click)="handleAccordionClick()">
       <span class="{{getClass(color)}}">{{ title | translate }} {{count?'('+count+')':''}}</span>
      </button>
    </h2>
    <div [id]="itemId" class="accordion-collapse collapse" [class.show]="open">
      <div class="accordion-body">
        <div class="row">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
